@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "Alfabet";
        font-weight: 300;
        font-style: normal;
        src: url('fonts/Alfabet-Regular.otf');
    }

    @font-face {
        font-family: "Alfabet Semi Bold";
        font-weight: 600;
        font-style: bold;
        src: url('fonts/Alfabet-SemiBold.otf');
    }

}

/* Mapa */
.marker.selected {
    background-color: rgba(0, 255, 89, 0.848);
    border-color: rgba(0,255,89,1);
    border-width: 5px;
    border-spacing: 5px;
    width: 30px!important;
    height: 64px!important;
}

.text-shadow {
    text-shadow: 2px 2px 2px #000000;
}